"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FsdChapters = void 0;
// TODO: Dynamically create these from intro.json or full-stack.json
var FsdChapters;
(function (FsdChapters) {
    FsdChapters["Welcome"] = "freecodecamp";
    FsdChapters["Html"] = "html";
    FsdChapters["Css"] = "css";
    FsdChapters["Javascript"] = "javascript";
    FsdChapters["FrontendLibraries"] = "frontend-libraries";
    FsdChapters["RelationalDatabases"] = "relational-databases";
    FsdChapters["BackendJavascript"] = "backend-javascript";
    FsdChapters["Python"] = "python";
})(FsdChapters || (exports.FsdChapters = FsdChapters = {}));
